<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Établissement.JURIS" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter les données JURIS d'un établissement</div>
              </v-row>
            </v-card-title>

            <!-- les noms digitaux -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">établissement</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    v-model="establishment"
                    :items="establishments"
                    item-text="digitalName"
                    return-object
                    placeholder="Choisir un établissement"
                    clearable
                    no-data-text="aucun établissement"
                    class="ma-0 pa-0"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
               <v-divider></v-divider>
            </v-card-text>                

            <!-- le numéro siret -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">siret</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le siret"
                    dense
                    v-model="siret"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>        

            <!-- le code ape -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">Code APE (établissement secondaire)</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le code APE"
                    dense
                    v-model="ape"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>      

            <!-- le référent -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">Administrateur principal d'établissement</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="L'administrateur"
                    dense
                    v-model="establishmentMainManager"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>    

            <!-- le nom finess -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom finess</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom finess"
                    dense
                    v-model="finessName"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>           

            <!-- le numéro finess -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">numéro finess</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le numéro finess"
                    dense
                    v-model="finessNumber"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>                

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { EstablishmentJurisService } from "@/service/etablishment/establishment_juris_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddStockholder",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      serviceJuris: null,
      serviceEstablishment: null,

      /** La liste des établissement qui n'ont pas de Juris */
      establishments: [],

      /** l'établissement choisi */
      establishment: null,
      /** le nom finess */
      finessName: null,
      /** le numéro finess */
      finessNumber: null,
      /** le siret */
      siret: null,
      /** le code ape */
      ape: null,
      /** le référent */
      establishmentMainManager: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération des établisssement.juris et des établissements
        let juris = await this.serviceJuris.getAll();
        let establishments = await this.serviceEstablishment.getEstablishments();

        // Filtrage sur tous les établissements qui n'ont pas d'entité Juris associé
        let ids = juris.map((j) => j.establishment);
        let availableEstablishments = establishments.filter((e) => !ids.includes(e.id));
 
        this.establishments = [...availableEstablishments];        
      }catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    async save() {
      // enregistrement
      if (this.entityHasChanged) {
        try {
          let entity = {};
          entity.establishment = this.establishment.id;
          entity.finessName = this.finessName;
          entity.finessNumber = this.finessNumber;
          entity.siret = this.siret;
          entity.ape = this.ape;
          entity.establishmentMainManager = this.establishmentMainManager;

          await this.serviceJuris.create(entity);

          this.$router.go(-1);
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de la partie Juris d'un établissement : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
    },
  },
  computed: {
    canSave() {
      try {
        if (!this.establishment) {
          return false;
        }

        let changed = false;

        if (this.entityHasChanged) {
          changed = true;
        }

        return changed;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    
    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (this.finessName) {
        changed = true;
      }
      if (this.finessNumber) {
        changed = true;
      }
      if (this.siret) {
        changed = true;
      }
      if (this.ape) {
        changed = true;
      }
      if (this.establishmentMainManager) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.serviceJuris = new EstablishmentJurisService(this.$api.getEstablishmentJurisApi());
    this.serviceEstablishment = new EstablishmentsService(this.$api);
    
    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>